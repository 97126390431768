import { ValidationError } from 'yup';

interface Errors {
    [key: string]: string;
}

export default function getValidationsErrors(error: ValidationError): Errors {
    const validationErrors: Errors = {};

    error.inner.forEach(err => {
        if (err.path) validationErrors[err.path] = err.message;
    });

    return validationErrors;
}

interface APIValidationError {
    campo: string;
    mensagem: string;
}

export function getAPIValidationErrors(err: APIValidationError[]): Errors {
    const validationErrors: Errors = {};

    err.forEach(error => {
        validationErrors[error.campo] = error.mensagem;
    });

    return validationErrors;
}
