import React from 'react';

const Footer: React.FC = () => {
    function getDateBase() {
        const now = new Date();
        const onejan = new Date(now.getFullYear(), 0, 1);

        return {
            week: Math.ceil(
                ((now.getTime() - onejan.getTime()) / 86400000 +
                    onejan.getDay() +
                    1) /
                    7,
            ),
            year: now.getFullYear(),
        };
    }

    const objDate = getDateBase();

    return (
        <>
            <footer>
                <div className="container">
                    <div className="wrapper">
                        <div className="letters">LD</div>
                        <div className="names">LARISSA E DANILO</div>
                        <hr />
                    </div>
                    <div className="credits">
                        <span>
                            Design e desenvolvimento por{' '}
                            <a
                                href="https://daniloriti.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Danilo Diego Riti
                            </a>
                        </span>
                        <span>
                            Copyright &#169; {objDate.year} Todos os direitos
                            reservados.
                        </span>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
