import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
    background: #fff;
    padding: 16px;
    width: 100%;

    border-bottom: 1px solid #a1a1a1;
    color: #a1a1a1;

    display: flex;
    align-items: center;

    & + div {
        margin-top: 8px;
    }

    ${props =>
        props.isErrored &&
        css`
            border-bottom-color: black;
        `}

    ${props =>
        props.isFocused &&
        css`
            color: black;
            border-bottom-color: black;
        `}

  ${props =>
        props.isFilled &&
        css`
            color: black;
        `}

  textarea {
        flex: 1;
        background: transparent;
        border: 0;
        color: #a1a1a1;
        min-height: 100px;

        &::placeholder {
            color: #a1a1a1;
        }

        ${props =>
            props.isFocused &&
            css`
                color: black;
            `}

        ${props =>
            props.isFilled &&
            css`
                color: black;
            `}
    }

    svg {
        margin-right: 16px;
    }
`;

// export const Error = styled(Tooltip)`
//     height: 20px;
//     margin-left: 16px;
//     svg {
//         margin: 0;
//     }

//     span {
//         background: black;
//         color: black;

//         &::before {
//             border-color: black transparent;
//         }
//     }
// `;

export const Error = styled.div`
    margin-left: 16px;
    display: flex;
    font-style: italic;
    color: #c53030;

    svg {
        margin: 0;
        margin-right: 10px;
    }
`;
