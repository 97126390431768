import React, { ButtonHTMLAttributes } from 'react';

import Loader from '../Loader';

import { Container } from './styles';

export enum ButtonTypes {
    default = 'default',
    transparent = 'transparent',
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
    styleType?: ButtonTypes;
    large?: boolean;
};

const Button = ({
    children,
    loading,
    styleType,
    large,
    className,
    ...rest
}: ButtonProps) => (
    <Container
        type="button"
        styleType={styleType || ButtonTypes.default}
        className={`${className ? `${className} ` : ''}${large ? 'w-100' : ''}`}
        {...rest}
    >
        {loading ? <Loader /> : children}
    </Container>
);

export default Button;
