import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Footer from 'components/Footer';

import ReactGA from 'react-ga';
import cover_flowers from '../../assets/images/cover_flowers.png';
import HeartsRings from '../../assets/images/heartsRings';

type MainProps = {
    children: React.ReactNode;
};

function intro() {
    document
        .getElementById('intro')
        ?.querySelector('.top')
        ?.classList.toggle('up');
    document
        .getElementById('intro')
        ?.querySelector('.bottom')
        ?.classList.toggle('down');
    document
        .getElementById('intro')
        ?.querySelector('.left')
        ?.classList.toggle('goLeft');
    document
        .getElementById('intro')
        ?.querySelector('.right')
        ?.classList.toggle('goRight');
    // document.getElementsByClassName('top')[0].classList.toggle('up');
    // document.getElementsByClassName('bottom')[0].classList.toggle('down');
}

function removeIntro() {
    document.getElementById('intro')?.remove();
}

// function fallingLeaves() {
//     document
//         .getElementById('fallingLeaves')
//         ?.classList.add('fallingLeavesShow');
// }

const Main = ({ children }: MainProps) => {
    const location = useLocation();

    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }

    useEffect(() => {
        ReactGA.pageview(location.pathname);

        if (location.pathname !== '/') {
            const header = document.getElementsByTagName('header');

            if (header != null) {
                header[0].className += ' no-border';
            }
        }

        (async function teste() {
            await timeout(1000);
            intro();
            await timeout(1000);
            removeIntro();
        })();
    }, [location]);

    return (
        <>
            <div id="intro">
                <div className="top" />
                <div className="bottom" />
                <div className="left" />
                <div className="right" />
            </div>
            <header>
                <div className="cover" />
                <div className="gradient" />
                <div className="gradient-bottom" />
                <img
                    className="cover-flowers-left"
                    alt="Flores esquerda"
                    src={cover_flowers}
                />
                <img
                    className="cover-flowers-right"
                    alt="Flores direita"
                    src={cover_flowers}
                />
                <HeartsRings className="rings" />
                <div
                    className="names"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    data-aos-easing="ease-in-out-back"
                >
                    Larissa &amp; Danilo
                </div>
            </header>
            <main className="triangle">{children}</main>
            <Footer />
        </>
    );
};

export default Main;
