import React from 'react';
import { Label, Required } from './styles';

type LabelProps = {
    children: React.ReactNode;
    required?: boolean;
};

export default function LabelComponent({ required, children }: LabelProps) {
    return (
        <Label>
            {children}
            {required && <Required> *</Required>}
        </Label>
    );
}
