import styled, { css } from 'styled-components';
import { ButtonTypes } from '.';

interface ButtonProps {
    styleType: string;
}

export const Container = styled.button<ButtonProps>`
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    font-weight: 500;
    margin-top: 10px;
    transition: background-color 0.3s;
    font-size: 1rem;

    ${props =>
        props.styleType === ButtonTypes.default &&
        css`
            color: black;

            &:hover {
                background: rgb(0, 0, 0, 0.15);
            }
        `};

    ${props =>
        props.styleType === ButtonTypes.transparent &&
        css`
            color: black;

            &:hover {
                background: black;
            }
        `};
`;
