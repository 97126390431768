import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import ReactGA from 'react-ga';
import Routes from './routes';
import AppProvider from './hooks';
import GlobalStyle from './styles/global';
import './styles/styles.scss';

export default function App() {
    useEffect(() => {
        ReactGA.initialize('UA-199125682-1');

        ReactGA.pageview(window.location.pathname);
    }, []);

    return (
        <Router>
            <AppProvider>
                <Routes />
            </AppProvider>
            <GlobalStyle />
        </Router>
    );
}
