import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Spinner = styled.div`
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #333;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
