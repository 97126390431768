/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import { IoVolumeHighOutline, IoVolumeMuteOutline } from 'react-icons/io5';

export default function AudioComponent() {
    const [audio] = useState(
        new Audio('https://www.larissaedanilo.com.br/XywZtrw/audio.mp3'),
    );
    const [playing, setPlaying] = useState(false);
    const [buttonHover, setButtonHover] = useState(false);

    useEffect(() => {
        try {
            audio.volume = 0.4;
            audio
                .play()
                .then(() => {
                    setPlaying(true);
                })
                .catch(() => {
                    // console.log('error tentativa 1');

                    setTimeout(() => {
                        if (!playing) {
                            audio
                                .play()
                                .then(() => {
                                    setPlaying(true);
                                })
                                .catch(() => {
                                    // console.log('error tentativa 2');
                                });
                        } else {
                            // console.log('usuário deu play antes da tentativa 2');
                        }
                    }, 5000);
                });
        } catch {}
    }, []);

    useEffect(() => {
        audio.volume = 0.4;
        playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    const btnPlay = () => {
        setPlaying(!playing);
    };

    return (
        <>
            <button
                id="btnPlay"
                onClick={btnPlay}
                type="button"
                onMouseEnter={() => setButtonHover(true)}
                onMouseLeave={() => setButtonHover(false)}
            >
                {playing ? (
                    buttonHover ? (
                        <IoVolumeMuteOutline />
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="equalizer"
                            viewBox="0 0 128 128"
                        >
                            <g>
                                <title>Music Equalizer</title>
                                <rect
                                    className="bar"
                                    transform="translate(0,0)"
                                    y="15"
                                ></rect>
                                <rect
                                    className="bar"
                                    transform="translate(25,0)"
                                    y="15"
                                ></rect>
                                <rect
                                    className="bar"
                                    transform="translate(50,0)"
                                    y="15"
                                ></rect>
                                <rect
                                    className="bar"
                                    transform="translate(75,0)"
                                    y="15"
                                ></rect>
                                <rect
                                    className="bar"
                                    transform="translate(100,0)"
                                    y="15"
                                ></rect>
                            </g>
                        </svg>
                    )
                ) : (
                    <IoVolumeHighOutline />
                )}
            </button>
        </>
    );
}
