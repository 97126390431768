import React from 'react';

import { Container, Spinner } from './styles';

const Loader: React.FC = () => {
    return (
        <Container>
            <Spinner />
        </Container>
    );
};

export default Loader;
