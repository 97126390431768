import styled from 'styled-components';

export const Label = styled.label`
    color: ${props => props.theme.global.text};
`;

export const Required = styled.span`
    font-weight: 600;
    color: ${props => props.theme.global.secundary};
`;
