import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={44}
            height={44}
            viewBox="0 -1 11.187 15.545"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g transform="translate(-165.68 -165.43)">
                <path
                    d="m168.72 178.96c-3.3272-0.072-3.0341 0.4943-3.0341-5.8603v-5.0088l0.38563-0.3622c0.36061-0.3388 0.52958-0.3666 2.6046-0.4291l2.219-0.067v0.8379c0 0.9907 0.40415 2.2454 1.1567 3.591 0.54776 0.9795 2.0112 2.8916 2.7134 3.5454l0.37767 0.3516 0.75709-0.8879c0.4164-0.4883 0.90853-1.0867 1.0936-1.3298l0.33652-0.442v2.6167c0 3.5661 0.21328 3.3536-3.4473 3.4349-1.5168 0.034-3.8401 0.038-5.1629 0.01zm2.3065-1.714c0.59536-0.4239 0.89173-0.9707 0.9634-1.7776l0.0545-0.6128-2.528-0.09v0.4578c0 0.4443 0.0238 0.4593 0.80437 0.5042l0.80438 0.046-0.50534 0.5171c-0.45197 0.4625-0.57089 0.5083-1.1259 0.4339-0.34128-0.046-0.78505-0.2378-0.98615-0.4267-0.69492-0.6528-0.61843-1.9878 0.14453-2.5222 0.36507-0.2557 1.4921-0.3004 1.7241-0.068 0.10485 0.1048 0.2687 0.035 0.50476-0.2168l0.34952-0.372-0.35921-0.2351c-1.8103-1.1848-4.3748 0.7033-3.809 2.8043 0.19923 0.7399 0.82039 1.5083 1.4504 1.7941 0.67073 0.3044 1.9187 0.1867 2.5137-0.2369zm3.1839-4.6487c-0.40263-0.5258-1.0171-1.5311-1.3654-2.2341-0.52024-1.0498-0.6299-1.4234-0.61424-2.0924 0.0215-0.9194 0.27613-1.5042 0.91065-2.0914 0.66028-0.6111 1.0226-0.7465 1.9954-0.7459 1.0422 0 1.6337 0.3007 2.2905 1.1619 0.38895 0.5099 0.43908 0.701 0.43908 1.6741 0 0.9702-0.0679 1.2312-0.58137 2.2336-0.56313 1.0994-1.9862 3.0502-2.2251 3.0502-0.0646 0-0.44697-0.4302-0.84961-0.956zm1.6864-3.3908c0.84999-0.7311 0.30521-2.2415-0.8085-2.2415-0.46519 0-1.282 0.7671-1.282 1.204 0 1.223 1.1889 1.813 2.0905 1.0375z"
                    strokeWidth=".15321"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
