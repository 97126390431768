import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    /* Modal */

    .react-modal-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.32);

        z-index: 2;
    }

    .react-modal-content {
        width: 100%;
        max-height: 95vh;
        overflow-y: auto;
        z-index: 2;
        /* max-width: 576px; */
        padding: 1.5rem;
        position: relative;
        border-radius: 4px;
        box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
        border: 0 none;
    }

    .avoidScroll {
        overflow: hidden;
    }
`;
