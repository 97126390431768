import React from 'react';

const CountDownTimer = () => {
    const data = '2022/08/27 16:30';
    const now = new Date().getTime();

    const timeleft = new Date(data).getTime() - now;
    const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

    const [[ds, hrs, mins, secs], setTime] = React.useState([
        days < 0 ? 0 : days,
        hours < 0 ? 0 : hours,
        minutes < 0 ? 0 : minutes,
        seconds < 0 ? 0 : seconds,
    ]);

    const eventDay =
        new Date(data).toLocaleDateString() === new Date().toLocaleDateString();

    const celebrationTime = timeleft <= 0;

    const dateToMilliseconds = new Date(data).getTime();
    let addedHours = dateToMilliseconds + 3600000 * 3;
    const partyTime = new Date(addedHours).getTime() - now <= 0;

    addedHours = dateToMilliseconds + 3600000 * 9;
    const married = new Date(addedHours).getTime() - now <= 0;

    const reset = () => setTime([0, 0, 0, 0]);

    const tick = () => {
        if (hrs === 0 && mins === 0 && secs === 0) reset();
        else if (mins === 0 && secs === 0) {
            setTime([ds, hrs - 1, 59, 59]);
        } else if (secs === 0) {
            setTime([ds, hrs, mins - 1, 59]);
        } else {
            setTime([ds, hrs, mins, secs - 1]);
        }
    };

    React.useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });

    const message = React.useCallback(() => {
        if (married) return 'Estamos casados! 👰🏽🤵🏽';
        if (partyTime) return 'Hora da festa! 🎉💃🏼🕺🏼';
        if (celebrationTime) return 'Hora da cerimônia! 🙏🏽';
        return '';
    }, []);

    return celebrationTime || partyTime || married ? (
        <>
            <div className="message">{message()}</div>
        </>
    ) : (
        <>
            <div>
                {`${
                    parseInt(ds.toString(), 10) === 0 && eventDay
                        ? '❤'
                        : parseInt(ds.toString(), 10)
                }`}
                <span>dias</span>
            </div>
            <div>
                {`${
                    hrs.toString() === '0' && eventDay
                        ? '❤'
                        : hrs.toString().padStart(2, '0')
                }`}
                <span>horas</span>
            </div>
            <div>
                {`${
                    mins.toString() === '0' && eventDay
                        ? '❤'
                        : mins.toString().padStart(2, '0')
                }`}
                <span>minutos</span>
            </div>
            <div>
                {`${
                    secs.toString() === '0' && eventDay
                        ? '❤'
                        : secs.toString().padStart(2, '0')
                }`}
                <span>segundos</span>
            </div>
        </>
    );
};

export default CountDownTimer;
