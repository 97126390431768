import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Home from '../pages/Home';
// import V1 from '../pages/V1';

const Routes = () => (
    <Switch>
        {/* <Route path="/" exact component={V1} /> */}
        <Route path="/" component={Home} />
    </Switch>
);

export default Routes;
