import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={44}
            height={44}
            viewBox="-5 -5 60 60"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g transform="matrix(.047893 0 0 .044897 -1.9463 -.44889)">
                <g transform="matrix(.1 0 0 -.1 0 511)">
                    <path d="m2904.7 4993.8c-142.2-44.2-244.1-132.6-305.6-271-38.4-82.6-40.4-123-40.4-918.6 0-703.4 3.9-841.7 28.8-901.3 78.8-190.2 244.1-299.8 442-299.8 194.1 0 349.8 98 436.2 271 44.2 90.3 44.2 92.3 44.2 916.7 0 914.8 0 916.7-123 1053.1-122.9 136.5-322.7 198-482.2 149.9z" />
                    <path d="m6809.7 4988c-99.9-34.6-246-176.8-286.4-280.6-30.7-82.6-34.6-163.4-34.6-916.7v-826.4l44.2-90.3c90.3-182.6 305.6-301.7 493.9-274.8 128.8 21.1 276.7 117.2 345.9 228.7l57.7 94.2 5.8 847.5c3.8 751.4 1.9 857.1-26.9 932.1-40.4 107.6-126.9 205.6-226.8 255.6-90.3 46.1-280.5 61.5-372.8 30.7z" />
                    <path d="m1017.5 3756.2c-228.7-78.8-417-240.2-522.7-449.7-90.3-178.7-88.4-76.9-88.4-3784 0-2409.9 5.8-3514.9 21.1-3593.7 44.2-244.1 221-492 434.3-607.3 215.2-119.2-38.4-111.5 4137.6-111.5 4174.1 0 3922.3-5.8 4135.6 109.5s392 367.1 436.3 609.2c30.7 163.3 28.8 7022.1 0 7164.3-69.2 326.7-320.9 595.8-640 680.3-71.1 19.2-242.1 26.9-640 26.9h-543.8v-407.2c0-284.4-7.7-440.1-26.9-507.3-61.5-228.7-213.3-415.1-430.5-520.8-99.9-50-128.8-53.8-320.9-53.8-199.9 0-219.1 3.8-340.2 63.4-161.4 78.8-320.9 246-382.4 401.7-42.3 103.8-46.1 144.1-51.9 568.8l-5.8 455.5h-2381v-418.9c0-326.7-7.7-442-30.7-524.7-61.5-209.5-226.8-399.7-426.6-493.9-98-46.1-132.6-51.9-320.9-51.9-192.2 0-221 3.8-320.9 53.8-222.9 109.5-388.2 315.2-436.2 547.7-11.5 55.7-21.1 278.7-21.1 493.9v394h-551.5c-526.8-2-559.5-4-682.5-44.3zm7975.3-5158c-5.8-2594.4-5.8-2629-44.2-2680.9-21.1-26.9-63.4-67.3-96.1-86.5-55.7-32.7-205.6-34.6-3853.1-34.6h-3795.5l-69.2 44.2c-142.2 88.4-132.6-123-132.6 2778.9v2605.9h7994.6z" />
                    <path d="m2307 527.6c-142.2-75-171-148-171-443.9 0-219.1 3.9-255.6 42.3-322.9 23.1-40.4 73-94.2 111.5-117.2 63.4-40.4 86.5-42.3 518.9-42.3s455.5 1.9 518.9 42.3c38.4 23 88.4 76.9 111.5 117.2 36.5 67.3 42.3 103.8 42.3 320.9 0 228.7-3.8 251.8-48 328.6-30.8 53.8-74.9 94.2-130.7 121.1-76.9 36.5-115.3 40.4-497.7 40.4-396.1 0-417.3-1.9-498-44.2z" />
                    <path d="m4497.8 527.6c-142.2-75-171-148-171-443.9 0-219.1 3.8-255.6 42.3-322.9 23.1-40.4 73-94.2 111.5-117.2 63.4-40.4 86.5-42.3 518.9-42.3s455.5 1.9 518.9 42.3c38.4 23 88.4 76.9 111.5 117.2 38.4 67.3 42.3 103.8 42.3 322.9 0 296-28.8 369-171 443.9-80.7 42.3-101.9 44.2-501.6 44.2-399.9 0-421.1-1.9-501.8-44.2z" />
                    <path d="m6688.6 527.6c-142.2-75-171-148-171-443.9 0-219.1 3.8-255.6 42.3-322.9 23.1-40.4 73-94.2 111.5-117.2 63.4-40.4 86.5-42.3 518.9-42.3s455.4 1.9 518.9 42.3c38.4 23 88.4 76.9 111.5 117.2 38.4 67.3 42.3 103.8 42.3 322.9 0 296-28.8 369-171.1 443.9-80.7 42.3-101.8 44.2-501.6 44.2s-421-1.9-501.7-44.2z" />
                    <path d="m2308.9-1046.3c-142.2-73-173-148-173-445.8 0-219.1 3.9-255.6 42.3-322.9 23.1-40.4 73-94.2 111.5-117.2 63.4-40.4 86.5-42.3 520.8-42.3 449.7 0 453.5 0 522.7 48 124.9 86.5 148 153.7 148 434.3 0 296-28.8 369-171 443.9-80.7 42.3-101.9 44.2-499.7 44.2-394 0.1-420.9-1.9-501.6-42.2z" />
                    <path d="m4497.8-1048.2c-142.2-75-171-148-171-443.9 0-219.1 3.8-255.6 42.3-322.9 23.1-40.4 73-94.2 111.5-117.2 63.4-40.4 86.5-42.3 520.8-42.3 449.7 0 453.5 0 522.7 48 124.9 86.5 148 153.7 148 434.3 0 296-28.8 369-171 443.9-80.7 42.3-101.9 44.2-501.6 44.2-399.8 0.1-421-1.9-501.7-44.1z" />
                    <path d="m6688.6-1048.2c-142.2-75-171-148-171-443.9 0-219.1 3.8-255.6 42.3-322.9 23.1-40.4 73-94.2 111.5-117.2 63.4-40.4 86.5-42.3 518.9-42.3s455.4 1.9 518.9 42.3c38.4 23 88.4 76.9 111.5 117.2 38.4 67.3 42.3 103.8 42.3 322.9 0 296-28.8 369-171.1 443.9-80.7 42.3-101.8 44.2-501.6 44.2s-421-2-501.7-44.2z" />
                    <path d="m2308.9-2622.2c-142.2-73-173-148-173-445.8 0-219.1 3.9-255.6 42.3-322.9 82.6-146 126.8-159.5 563.1-165.3 468.9-7.7 528.5 0 618.8 78.8 103.8 90.3 121.1 151.8 121.1 420.9 0 282.5-30.7 357.4-171 432.4-80.7 42.3-101.9 44.2-499.7 44.2-394 0-420.9-1.9-501.6-42.3z" />
                    <path d="m4499.7-2622.2c-142.2-73-173-148-173-445.8 0-219.1 3.8-255.6 42.3-322.9 23.1-40.4 73-94.2 111.5-117.2 63.4-40.4 86.5-42.3 520.8-42.3 449.7 0 453.5 0 522.7 48 124.9 86.5 148 153.7 148 434.3 0 296-28.8 369-171 443.9-80.7 42.3-101.9 44.2-499.7 44.2-393.9 0.1-420.8-1.8-501.6-42.2z" />
                </g>
            </g>
        </svg>
    );
}

export default SvgComponent;
