export const photos = [
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/a.jpg',
        id: 0,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/b.jpg',
        id: 1,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/c.jpg',
        id: 2,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/d.jpg',
        id: 3,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/e.jpg',
        id: 4,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/h.jpg',
        id: 5,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/g.jpg',
        id: 6,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/f.jpg',
        id: 7,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/i.jpg',
        id: 8,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/n.jpg',
        id: 9,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/j.jpg',
        id: 10,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/l.jpg',
        id: 11,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/m.jpg',
        id: 12,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/k.jpg',
        id: 13,
    },
    {
        source: 'https://www.larissaedanilo.com.br/XywZtrw/o.jpg',
        id: 14,
    },
];
