/* eslint-disable no-new */
/* eslint-disable new-cap */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useToast } from 'hooks/toast';
import getValidationsErrors from 'utils/getValidationErrors';
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import Button from 'components/Button';
import axios from 'axios';

import Main from 'pages/Main';
import Countdown from 'components/Countdown';
import Audio from 'components/audio';

// import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Gallery from 'components/Gallery';
import FsLightbox from 'fslightbox-react';

// https://github.com/thebuilder/react-intersection-observer
import { InView } from 'react-intersection-observer';

import lottie from 'lottie-web/build/player/lottie_light';
import ReactGA from 'react-ga';

import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { FiMail } from 'react-icons/fi';
import { BsFillPersonFill } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import { FaHeart } from 'react-icons/fa';
import { photos } from '../assets/images/photos';
import flores from '../assets/images/flores.png';
import us1 from '../assets/photos/us1.jpg';
import bride_she from '../assets/images/bride_she.png';
import bride_he from '../assets/images/bride_he.png';
import btnInstagram from '../assets/images/btnInstagram.png';
import btnFacebook from '../assets/images/btnFacebook.png';
import btnLinkedin from '../assets/images/btnLinkedin.png';
import heart from '../assets/images/heart.png';
import titleLeft from '../assets/images/title-left.png';
import titleRight from '../assets/images/title-right.png';
import church from '../assets/images/church.png';
import marker from '../assets/images/marker.png';
import clock from '../assets/images/clock.png';
import car from '../assets/images/car.png';
import celebrationThumb1 from '../assets/images/celebration-thumb1.png';
import celebrationThumb2 from '../assets/images/celebration-thumb2.png';
import celebrationThumb3 from '../assets/images/celebration-thumb3.png';
import celebrationThumb4 from '../assets/images/celebration-thumb4.png';
import nossaSenhora from '../assets/images/nossaSenhora.png';
import freiSebastiao from '../assets/images/freiSebastiao.jpg';
import buffet from '../assets/images/buffet.jpg';
import buffetLogo from '../assets/images/buffetLogo.png';
import ld from '../assets/images/ld.png';
import Calendar from '../assets/images/calendar';
import cuteHeart from '../assets/images/cuteHeart.png';
import hashtag from '../assets/images/hashtag.png';
import smartphone from '../assets/images/smartphone.png';
import magazineluiza from '../assets/images/magalu.png';
import magaluText from '../assets/images/magaluText.png';
import flowerGift from '../assets/images/flowerGift.png';
import ldPhoto from '../assets/images/ldPhoto.png';
import timeline1 from '../assets/photos/timeline1.jpg';
import timeline2 from '../assets/photos/timeline2.jpg';
import WazeSvg from '../assets/images/waze';
import GoogleMapsSvg from '../assets/images/googleMaps';
import Route from '../assets/images/route';
import animatedCar from '../assets/images/car.json';
import animatedPassaros1 from '../assets/images/passaros1.json';
import animatedPassaros2 from '../assets/images/passaros2.json';
import animatedPassaros3 from '../assets/images/passaros3.json';
import animatedPassaros4 from '../assets/images/passaros4.json';
import animatedPassaros5 from '../assets/images/passaros5.json';

interface SignInFormData {
    email: string;
    senha: string;
}

export default function Home() {
    const { addToast } = useToast();
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState(false);
    const API_PATH = 'https://www.larissaedanilo.com.br/api/contact/index.php';

    const eventTracker = (category: string, action: string, label: string) => {
        ReactGA.event({ category, action, label });
    };

    const handleSubmit = useCallback(
        async (data: SignInFormData, { reset }) => {
            try {
                setLoading(true);
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    email: Yup.string()
                        .trim()
                        .optional()
                        .email('Por favor, informe um e-mail válido.'),
                    nome: Yup.string()
                        .trim()
                        .min(3, 'Por favor, nos diga seu nome.'),
                    mensagem: Yup.string()
                        .trim()
                        .min(
                            5,
                            'Poesias e declarações não são tão belas quanto sua mensagem...',
                        ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                const email =
                    formRef.current?.getFieldValue('email').trim() === ''
                        ? 'contato@larissaedanilo.com.br'
                        : formRef.current?.getFieldValue('email').trim();

                const dados = {
                    firstName: formRef.current?.getFieldValue('nome').trim(),
                    email,
                    message: formRef.current?.getFieldValue('mensagem').trim(),
                    mailSent: false,
                    error: null,
                };

                axios({
                    method: 'post',
                    url: `${API_PATH}`,
                    headers: { 'content-type': 'application/json' },
                    data: dados,
                })
                    .then(() => {
                        addToast({
                            type: 'success',
                            title: 'Muito obrigado!',
                            description: 'Logo logo nós vamos ler! 😉',
                        });
                    })
                    .catch(() => {
                        addToast({
                            type: 'error',
                            title: 'Algo deu errado! 😭',
                            description:
                                'Por favor, tente novamente mais tarde.',
                        });
                    });

                reset();
                setLoading(false);
            } catch (err) {
                setLoading(false);

                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationsErrors(err);
                    formRef.current?.setErrors(errors);
                }

                addToast({
                    type: 'error',
                    title: 'Quase lá!',
                    description: 'Revise algumas informações!',
                });
            }
        },
        [addToast],
    );

    const [dimensions, setDimensions] = useState({
        // height: window.innerHeight,
        width: window.innerWidth,
    });

    const handleHashtagEmoji = useCallback(() => {
        if (dimensions.width >= 950) {
            return '👈';
        }

        return '👆';
    }, [dimensions]);

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((index: number) => {
        document.body.style.setProperty('margin-right', '0px', 'important');
        document.documentElement.style.overflow = 'hidden';
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        document.documentElement.style.overflow = '';
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    useEffect(() => {
        const debouncedHandleResize = function handleResize() {
            setDimensions({
                // height: window.innerHeight,
                width: window.innerWidth,
            });
        };

        window.addEventListener('resize', debouncedHandleResize);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
            // console.log(dimensions.width);
        };
    });

    const carElement = useRef<HTMLDivElement>(null);
    const passaros1Element = useRef<HTMLDivElement>(null);
    const passaros2Element = useRef<HTMLDivElement>(null);
    const passaros3Element = useRef<HTMLDivElement>(null);
    const passaros4Element = useRef<HTMLDivElement>(null);
    const passaros5Element = useRef<HTMLDivElement>(null);
    const [passaros1Trigger, setPassaros1Trigger] = useState(false);
    const [passaros2Trigger, setPassaros2Trigger] = useState(false);
    const [passaros3Trigger, setPassaros3Trigger] = useState(false);
    const [passaros5Trigger, setPassaros5Trigger] = useState(false);

    useEffect(() => {
        if (carElement.current)
            lottie.loadAnimation({
                animationData: animatedCar,
                container: carElement.current,
                loop: true,
            });

        if (passaros1Element.current)
            lottie.loadAnimation({
                animationData: animatedPassaros1,
                container: passaros1Element.current,
                loop: false,
                autoplay: false,
                name: 'passaros1',
            });

        if (passaros2Element.current)
            lottie.loadAnimation({
                animationData: animatedPassaros2,
                container: passaros2Element.current,
                loop: false,
                autoplay: false,
                name: 'passaros2',
            });

        if (passaros3Element.current)
            lottie.loadAnimation({
                animationData: animatedPassaros3,
                container: passaros3Element.current,
                loop: false,
                autoplay: false,
                name: 'passaros3',
            });

        if (passaros4Element.current)
            lottie.loadAnimation({
                animationData: animatedPassaros4,
                container: passaros4Element.current,
                loop: true,
                autoplay: true,
                name: 'passaros4',
            });

        if (passaros5Element.current)
            lottie.loadAnimation({
                animationData: animatedPassaros5,
                container: passaros5Element.current,
                loop: false,
                autoplay: false,
                name: 'passaros5',
            });
    }, []);

    useEffect(() => {
        if (passaros1Trigger) {
            lottie.play('passaros1');
        } else {
            lottie.stop('passaros1');
        }
    }, [passaros1Trigger]);

    useEffect(() => {
        if (passaros2Trigger) {
            lottie.play('passaros2');
        } else {
            lottie.stop('passaros2');
        }
    }, [passaros2Trigger]);

    useEffect(() => {
        if (passaros3Trigger) {
            lottie.play('passaros3');
        } else {
            lottie.stop('passaros3');
        }
    }, [passaros3Trigger]);

    useEffect(() => {
        if (passaros5Trigger) {
            lottie.play('passaros5');
        } else {
            lottie.stop('passaros5');
        }
    }, [passaros5Trigger]);

    // const copyToClipBoard = async (copyMe: string) => {
    //     try {
    //         await navigator.clipboard.writeText(copyMe);
    //         addToast({
    //             type: 'success',
    //             title: 'Hashtag copiada!',
    //             description: 'Adicione em suas postagens agora mesmo! 🥰',
    //         });
    //     } catch (err) {
    //         addToast({
    //             type: 'error',
    //             title: 'Ops! 😭',
    //             description:
    //                 'Tem algo de errado com seu navegador, melhor escrever a hashtag diretamente em suas postagens.',
    //         });
    //     }
    // };

    const copyMessage = async () => {
        addToast({
            type: 'success',
            title: 'Hashtag copiada!',
            description: 'Adicione em suas postagens agora mesmo! 🥰',
        });
    };

    const [isLoaderRunning, setLoader] = useState(false);
    document.onreadystatechange = () => {
        setLoader(document.readyState === 'complete');
    };

    useEffect(() => {
        if (isLoaderRunning) {
        }
    }, [isLoaderRunning]);

    const [toggler1, setToggler1] = useState(false);
    const [toggler2, setToggler2] = useState(false);

    return (
        <Main>
            <section className="date">27 de Agosto de 2022</section>
            <section className="count">
                <Countdown />
            </section>
            <div className="line" />
            <InView
                as="div"
                onChange={inViewLocal => setPassaros1Trigger(inViewLocal)}
            >
                <div ref={passaros1Element} className="passaros1" />
            </InView>
            <VerticalTimeline className="timeline">
                <VerticalTimelineElement
                    className="timeline-icon-first"
                    iconStyle={{
                        background: '#d7a39f',
                        color: '#fff',
                    }}
                />
                <VerticalTimelineElement
                    position="right"
                    className="vertical-timeline-element--work"
                    iconStyle={{
                        background: '#d7a39f',
                        color: '#fff',
                    }}
                    icon={<FaHeart />}
                >
                    <h3 className="vertical-timeline-element-title">
                        Quando nos conhecemos
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">2002</h4>
                    <p>
                        Pode não parecer e também não ser do conhecimento de
                        todos, mas nossa história já ultrapassa 20 anos! Nos
                        conhecemos na escola, por volta da quinta série, e
                        seguimos quase sempre juntos, na mesma sala, até o
                        terceiro ano do ensino médio. Éramos grandes amigos, e
                        embora o amor já estivesse nascendo, a amizade nunca
                        fora ultrapassada.
                        <span onClick={() => setToggler1(!toggler1)}>
                            <img src={timeline1} alt="Nós" />
                        </span>
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    position="left"
                    className="vertical-timeline-element--work"
                    iconStyle={{
                        background: '#d7a39f',
                        color: '#fff',
                    }}
                    icon={<FaHeart />}
                >
                    <h3 className="vertical-timeline-element-title">
                        Quer namorar comigo?
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        12 Junho, 2013
                    </h4>
                    <p>
                        Alguns anos se passaram, e após conturbados
                        relacionamentos de ambas as partes, intencionalmente nos
                        reencontramos. Saímos algumas vezes, e o primeiro beijo
                        não demorou a acontecer. No dia 12/06/2013 fomos à uma
                        linda pizzaria, e em meio a champagne, luz de velas,
                        pelúcia, músicas românticas e um enorme buquê de flores,
                        o pedido foi feito. Desde então, a amizade e parceria só
                        tem aumentado, temperados, porém, com o ingrediente
                        especial do amor.
                        <span onClick={() => setToggler2(!toggler2)}>
                            <img src={timeline2} alt="Nós" />
                        </span>
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="timeline-icon-last"
                    iconStyle={{
                        background: '#d7a39f',
                        color: '#fff',
                    }}
                />
            </VerticalTimeline>
            <FsLightbox
                toggler={toggler1}
                sources={[
                    'https://www.larissaedanilo.com.br/XywZtrw/timeline1.jpg',
                ]}
                types={[...new Array(1).fill('image')]}
            />
            <FsLightbox
                toggler={toggler2}
                sources={[
                    'https://www.larissaedanilo.com.br/XywZtrw/timeline2.jpg',
                ]}
                types={[...new Array(1).fill('image')]}
            />
            <section className="us">
                <div className="background-title">Nós</div>
                <div className="box">
                    <div className="background-square">
                        <span>Ela disse sim!...</span>
                    </div>
                    <div className="images">
                        <img src={flores} alt="Flores" className="flower" />
                        <div className="us-gallery">
                            <img src={us1} alt="us" />
                        </div>
                    </div>
                    <div className="title">QUER CASAR COMIGO?</div>
                    <div className="text">
                        Demoraram somente sete anos pra aceitarmos a
                        desconfortável ideia de convivermos um com o outro até o
                        final da vida (rs)! Na verdade, a condição financeira
                        não nos favoreceu antes disso, e ao contrário da
                        brincadeira, o passar do tempo só reforçou nossa vontade
                        de &quot;juntar as escovas&quot;. No dia 12/09/18, um
                        dos mais esperados SIMs foi dito. Um momento carregado
                        de emoção, que talvez a foto seja capaz de transmitir. A
                        partir de então, temos preparado tudo para o próximo
                        SIM.
                        <br />
                        <br />
                        Gostaria de saber mais sobre nossa história? Conheça no
                        dia 27/08/22. Teremos o maior prazer em mostrar!
                    </div>
                </div>
            </section>
            <InView
                as="div"
                onChange={inViewLocal => setPassaros2Trigger(inViewLocal)}
            >
                <div ref={passaros2Element} className="passaros2" />
            </InView>
            <section className="about">
                <div className="bride" data-aos="fade-right">
                    <div className="image">
                        <img src={bride_she} alt="us" />
                    </div>
                    <div className="text">
                        <span>Gondo, Larissa.</span>
                        <p>
                            Ainda está aí? Então falarei um pouco de mim. Tenho
                            34 anos, e embora não pareça, sou 8 meses mais velha
                            que o Danilo... rsrs.
                        </p>
                        <p>
                            Sou biomédica, apaixonada por minha profissão!
                            Atualmente, trabalho no laboratório de análises
                            clínicas do Hospital de Urgência de SBC, realizando
                            e liberando exames.
                        </p>
                        <p>
                            Sou católica, e já fui até mesmo coroinha e
                            catequista de crianças. Eu amo a natureza, os
                            animais, hambúrgueres e balas Fini, estampas, redes
                            sociais, Barbies, dança do ventre, Legião Urbana,
                            Alok, O Rei Leão, DIY, origami, papelaria, praia,
                            viajar, ouvir música, desenhar, pintar, dormir,
                            cochilar, adormecer e ninar.
                        </p>
                        <p>
                            Dizem que sou meiga, extrovertida, sincera, dou bons
                            conselhos e guardo segredo como ninguém! Dou risada
                            de qualquer bobagem, e pelo menos umas três vezes
                            por semana, choro de rir.
                        </p>
                        <p>
                            Sou grata por minha vida, por minha família, por
                            meus amigos e pelo meu relacionamento. Sinto-me
                            privilegiada por tudo que tenho e por este sonho que
                            estamos realizando.
                        </p>
                        <div className="links">
                            <a
                                href="https://instagram.com/larissa_gondo"
                                target="_blank"
                                rel="noreferrer"
                                data-aos="zoom-in-up"
                                data-aos-duration="1500"
                            >
                                <img src={btnInstagram} alt="Instagram" />
                            </a>
                            <a
                                href="https://facebook.com/larissa.gondo"
                                target="_blank"
                                rel="noreferrer"
                                data-aos="zoom-in-up"
                                data-aos-duration="1500"
                                data-aos-delay="300"
                            >
                                <img src={btnFacebook} alt="Facebook" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="hearts">
                    <img src={heart} alt="hearts" className="heart1" />
                    <img src={heart} alt="hearts" className="heart2" />
                </div>
                <div className="bride groom" data-aos="fade-left">
                    <div className="image">
                        <img src={bride_he} alt="us" />
                    </div>
                    <div className="text">
                        <span>Riti, Danilo.</span>
                        <p>
                            Tenho 33 anos, formação em ciência da computação e
                            gestão de projetos, atualmente sou arquiteto de
                            sistemas.
                        </p>
                        <p>
                            Cinéfilo de carteirinha, apaixonado por quadrinhos,
                            boa literatura e cultura geek, amo estudar e
                            qualquer conteúdo com um bom desafio é sempre bem
                            vindo.
                        </p>
                        <p>
                            Entre os meus pratos favoritos estão pizza,
                            hamburguer e um bom macarrão. Na área de doces,
                            bom... não cabe a lista toda a aqui, então, qualquer
                            coisa, literalmente qualquer coisa a base de cacau.
                        </p>
                        <p>
                            Nada como um bom rock e boa companhia para dar
                            risadas e bater um bom papo.
                        </p>
                        <p>
                            Sou muito grato a Deus e Nossa Senhora, por tantas e
                            tantas bençãos que sempre me proporciona.
                        </p>
                        <div className="links">
                            <a
                                href="https://instagram.com/daniloriti"
                                target="_blank"
                                rel="noreferrer"
                                data-aos="zoom-in-up"
                                data-aos-duration="1500"
                            >
                                <img src={btnInstagram} alt="Instagram" />
                            </a>
                            <a
                                href="https://facebook.com/daniloriti"
                                target="_blank"
                                rel="noreferrer"
                                data-aos="zoom-in-up"
                                data-aos-duration="1500"
                                data-aos-delay="300"
                            >
                                <img src={btnFacebook} alt="Facebook" />
                            </a>
                            <a
                                href="https://linkedin.com/in/daniloriti"
                                target="_blank"
                                rel="noreferrer"
                                data-aos="zoom-in-up"
                                data-aos-duration="1500"
                                data-aos-delay="600"
                            >
                                <img src={btnLinkedin} alt="Linkedin" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <InView
                as="div"
                onChange={inViewLocal => setPassaros3Trigger(inViewLocal)}
            >
                <div ref={passaros3Element} className="passaros3" />
            </InView>
            {/* <section className="infos" data-aos="zoom-in">
                <div className="background-title">Informações</div>
                <div className="box">
                    <div className="background-square">
                        <span>Ela disse sim!...</span>
                        <div className="title">
                            TO LOVE OR HAVE LOVED, THAT IS ENOUGHT.
                        </div>
                        <div className="text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud.
                        </div>
                    </div>
                    <div className="images">
                        <img
                            src={infosFlowers}
                            alt="Flores"
                            className="flower"
                        />
                        <div className="us-gallery">
                            <img src={infos1} alt="Us on Santos" />
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="celebration">
                <div className="title">
                    <img
                        src={titleLeft}
                        alt="Title decoration"
                        data-aos="zoom-in"
                    />
                    CELEBRAÇÃO
                    <img
                        src={titleRight}
                        alt="Title decoration"
                        data-aos="zoom-in"
                    />
                </div>
                <div className="content">
                    <div className="left">
                        <div className="item">
                            <h1 data-aos="fade-right">
                                <img src={church} alt="Church" />
                                Local
                            </h1>
                            <div
                                className="description"
                                data-aos="zoom-in-right"
                            >
                                Milícia da Imaculada - Santuário Imaculada
                                Conceição e Maximiliano Maria Kolbe
                            </div>
                        </div>
                        <div className="item">
                            <h1 data-aos="fade-right">
                                <img src={marker} alt="Marker" />
                                Endereço
                            </h1>
                            <div
                                className="description"
                                data-aos="zoom-in-right"
                            >
                                Estr. do Morro Grande, 870 - Finco, São Bernardo
                                do Campo - SP, 09831-250
                            </div>
                        </div>
                        <div className="item">
                            <h1 data-aos="fade-right">
                                <img src={clock} alt="Clock" />
                                Horário
                            </h1>
                            <div
                                className="description"
                                data-aos="zoom-in-right"
                            >
                                Início da celebração às 16h:30m.
                            </div>
                        </div>
                        <div className="item">
                            <h1 data-aos="fade-right">
                                <img src={car} alt="Car" />
                                Estacionamento
                            </h1>
                            <div
                                className="description"
                                data-aos="zoom-in-right"
                            >
                                O local possui estacionamento gratuito.
                            </div>
                        </div>
                        <div className="photo">
                            <img
                                src={nossaSenhora}
                                alt="Nossa senhora"
                                className="background"
                            />
                            {/* <img
                                src={celebration}
                                alt="Celebration"
                                data-aos="fade-up"
                                data-aos-duration="3000"
                            /> */}
                            <div>
                                <img
                                    src={celebrationThumb1}
                                    alt="Celebration"
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                />
                                <img
                                    src={celebrationThumb2}
                                    alt="Celebration"
                                    data-aos="fade-up"
                                    data-aos-duration="2000"
                                />
                                <img
                                    src={celebrationThumb3}
                                    alt="Celebration"
                                    data-aos="fade-up"
                                    data-aos-duration="2000"
                                />
                                <img
                                    src={celebrationThumb4}
                                    alt="Celebration"
                                    data-aos="fade-up"
                                    data-aos-duration="2000"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <figure
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1500"
                        >
                            <img src={freiSebastiao} alt="Title decoration" />
                            <figcaption>
                                Frei Sebastião Benito Qualio
                            </figcaption>
                        </figure>
                        <div className="howToArrive" data-aos="fade-left">
                            <h2>
                                <Route />
                                Como chegar
                            </h2>
                            <a
                                className="btnDefault"
                                href="https://ul.waze.com/ul?preview_venue_id=205456922.2054307076.2759221&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
                                target="_blank"
                                rel="nofollow noreferrer"
                            >
                                <WazeSvg />
                                <div>
                                    <div>
                                        Abrir no <span>Waze</span>
                                    </div>
                                </div>
                            </a>
                            <a
                                className="btnDefault"
                                href="https://www.google.com/maps/dir//Estr.%20do%20Morro%20Grande,%20870%20-%20Finco,%20S%C3%A3o%20Bernardo%20do%20Campo%20-%20SP,%2009831-250"
                                target="_blank"
                                rel="nofollow noreferrer"
                            >
                                <GoogleMapsSvg />
                                <div>
                                    <div>
                                        Abrir no <span>Google Maps</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                {/* <img
                    src={originDestiny}
                    alt="Origin to Destiny"
                    className="originDestiny"
                /> */}
                <div ref={carElement} className="originDestiny" />
            </section>
            <section className="party">
                <div className="title">
                    <img
                        src={titleLeft}
                        alt="Title decoration"
                        data-aos="zoom-in"
                    />
                    FESTA
                    <img
                        src={titleRight}
                        alt="Title decoration"
                        data-aos="zoom-in"
                    />
                </div>
                <div className="buffet">
                    <img
                        src={buffet}
                        alt="Buffet"
                        data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom"
                    />
                    <img src={buffetLogo} alt="Buffet logo" className="logo" />
                    <div className="teste" />
                </div>

                <div className="content">
                    <div className="left">
                        <div className="item">
                            <h1>Local</h1>
                            <div className="description" data-aos="fade-right">
                                Sítio São Jorge
                            </div>
                        </div>
                        <div className="item">
                            <h1>Endereço</h1>
                            <div className="description" data-aos="fade-right">
                                Rua das Araribás, 25 – Bairro dos Casa - São
                                Bernardo do Campo – SP
                            </div>
                        </div>
                        <div className="item">
                            <h1>Horário</h1>
                            <div className="description" data-aos="fade-right">
                                Abertura do salão às 19h:30m
                            </div>
                        </div>
                        <div className="item">
                            <h1>Estacionamento</h1>
                            <div className="description" data-aos="fade-right">
                                Valor único de R$ 25,00
                            </div>
                        </div>
                        <div
                            className="howToArrive"
                            data-aos="fade-right"
                            data-aos-delay="200"
                        >
                            <h2>
                                <Route />
                                Como chegar
                            </h2>
                            <a
                                className="btnDefault"
                                href="https://ul.waze.com/ul?preview_venue_id=205391387.2054044938.2850642&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
                                target="_blank"
                                rel="nofollow noreferrer"
                            >
                                <WazeSvg />
                                <div>
                                    <div>
                                        Abrir no <span>Waze</span>
                                    </div>
                                </div>
                            </a>
                            <a
                                className="btnDefault"
                                href="https://www.google.com/maps/dir//Rua%20das%20Ararib%C3%A1s,%2025%20%E2%80%93%20Bairro%20dos%20CasaS%C3%A3o%20Bernardo%20do%20Campo%20%E2%80%93%20SP"
                                target="_blank"
                                rel="nofollow noreferrer"
                            >
                                <GoogleMapsSvg />
                                <div>
                                    <div>
                                        Abrir no <span>Google Maps</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="right">
                        <div />
                        <img
                            src={ld}
                            alt="Larissa e Danilo letters"
                            data-aos="fade-up-left"
                        />
                    </div>
                </div>
            </section>
            <section className="map">
                <div className="title">
                    <img
                        src={titleLeft}
                        alt="Title decoration"
                        data-aos="zoom-in"
                    />
                    MAPA
                    <img
                        src={titleRight}
                        alt="Title decoration"
                        data-aos="zoom-in"
                    />
                </div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d58428.82398272978!2d-46.57217157009579!3d-23.754456987703136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x94ce3feb38942317%3A0x6b53621f1a798a5b!2sMil%C3%ADcia%20da%20Imaculada%20-%20Estr.%20do%20Morro%20Grande%2C%20870%20-%20Finco%2C%20S%C3%A3o%20Bernardo%20do%20Campo%20-%20SP%2C%2009831-250!3m2!1d-23.7954549!2d-46.536943099999995!4m5!1s0x94ce413d88145c17%3A0x5856713a1bc092ef!2sS%C3%ADtio%20S%C3%A3o%20Jorge%20-%20Rua%20das%20Ararib%C3%A1s%20-%20Bairro%20dos%20Casa%2C%20S%C3%A3o%20Bernardo%20do%20Campo%20-%20SP!3m2!1d-23.74402!2d-46.574889!5e0!3m2!1spt-BR!2sbr!4v1641739133566!5m2!1spt-BR!2sbr"
                    width="100%"
                    height="450"
                    style={{ border: '0' }}
                    allowFullScreen
                    loading="lazy"
                    title="Maps"
                    className="googleMap"
                />
                <div className="options">
                    <a
                        className="btnDefault"
                        href="https://www.google.com/maps?ll=-23.75886,-46.560083&z=12&t=m&hl=pt-BR&gl=BR&mapclient=embed&saddr=Mil%C3%ADcia+da+Imaculada,+Estr.+do+Morro+Grande,+870+-+Finco,+S%C3%A3o+Bernardo+do+Campo+-+SP,+09831-250&daddr=S%C3%ADtio+S%C3%A3o+Jorge,+R.+das+Ararib%C3%A1s,+25+-+Bairro+dos+Casa,+S%C3%A3o+Bernardo+do+Campo+-+SP,+09840-210&dirflg=d"
                        target="_blank"
                        rel="nofollow noreferrer"
                        data-aos="fade-right"
                        data-aos-delay="200"
                    >
                        <GoogleMapsSvg />
                        <div>
                            <div>Abrir mapa completo</div>
                        </div>
                    </a>
                    <a
                        className="btnDefault"
                        href="http://www.google.com/calendar/event?action=TEMPLATE&text=Casamento Larissa e Danilo&dates=20210827T183000Z%2F20210828T050000Z&ctz=America%2FSao_Paulo&details=Nosso casamento será realizado às 15h:30m e a comemoração às 19h. Para mais informações acesse: <a href='https://www.larissaedanilo.com.br'>www.larissaedanilo.com.br</a>.&location=Milícia da Imaculada, Estr. do Morro Grande, 870 - Finco, São Bernardo do Campo - SP&trp=false&sprop=&sprop=name:"
                        target="_blank"
                        rel="nofollow noreferrer"
                        data-aos="fade-left"
                        data-aos-delay="200"
                    >
                        <Calendar />
                        <div>
                            <div>Adicionar evento no calendário do Google</div>
                        </div>
                    </a>
                </div>
            </section>
            <div className="passaros4">
                <div ref={passaros4Element} />
            </div>
            <section className="gallery" id="gallery">
                <div className="title">
                    <img
                        src={titleLeft}
                        alt="Title decoration"
                        data-aos="zoom-in"
                    />
                    GALERIA
                    <img
                        src={titleRight}
                        alt="Title decoration"
                        data-aos="zoom-in"
                    />
                </div>
                <div className="content">
                    {/* <Gallery
                        photos={photos}
                        onClick={openLightbox}
                        direction="column"
                        columns={handleGalleryColumns()}
                    /> */}
                    <Gallery
                        photos={photos
                            .sort(
                                ({ id: previousID }, { id: currentID }) =>
                                    previousID - currentID,
                            )
                            .map(x => ({
                                source: x.source,
                                id: x.id,
                            }))}
                        onClick={openLightbox}
                    />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal
                                onClose={closeLightbox}
                                allowFullscreen={false}
                            >
                                <Carousel
                                    currentIndex={currentImage}
                                    views={photos
                                        .sort(
                                            (
                                                { id: previousID },
                                                { id: currentID },
                                            ) => previousID - currentID,
                                        )
                                        .map(x => ({
                                            ...x,
                                        }))}
                                    formatters={{
                                        getAltText: () => 'a',
                                        getNextLabel: () => 'Próximo',
                                        getPrevLabel: () => 'Anterior',
                                        getNextTitle: () => 'Próximo',
                                        getPrevTitle: () => 'Anterior',
                                        getCloseLabel: () => 'Fechar',
                                        getFullscreenLabel: () => 'Tela cheia',
                                    }}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </div>
            </section>
            <section className="socialMedia" id="socialMedia">
                <div className="wrapper">
                    <div className="left">
                        <h1>
                            <span>FOTOS?</span>
                            <span>VÍDEOS?</span>
                        </h1>
                        <h2>
                            <span>UTILIZE NOSSA</span>
                            <span>HASHTAG EM</span>
                            <span>SUAS POSTAGENS</span>
                        </h2>
                        <img src={hashtag} alt="Hashtag" />
                    </div>
                    <div className="content">
                        <div className="hashtag" data-aos="zoom-in-up">
                            <span>#CASAMENTO</span>
                            <span>LARI</span>
                            <span>DANI</span>
                            <CopyToClipboard
                                text="#CASAMENTOLARIDANI"
                                onCopy={() => copyMessage()}
                            >
                                <button type="button">
                                    {handleHashtagEmoji()} Copiar
                                </button>
                            </CopyToClipboard>
                        </div>
                        <div className="text">
                            Todas as fotos e vídeos postados nas redes socias
                            podem ser facilmente encontradas e você compartilha
                            muito mais!
                        </div>
                        {/* <div className="text">
                            Todas as fotos e vídeos postados nas redes socias
                            serão exibidos em nosso site e você compartilha
                            muito mais!
                        </div> */}
                        <div className="background">
                            <div data-aos="fade-left" data-aos-delay="200">
                                <img src={cuteHeart} alt="Cute heart" />
                            </div>
                            <img src={smartphone} alt="Smartphone" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="flash" id="flash" />
            <section className="whatWeLike">
                <div className="background" />
                <div className="background-title">O que nós gostamos</div>
                <div className="text">
                    Pra você que está disposto a mexer no bolso, gostamos e
                    precisamos de tudo mencionado nas listas. O casamento acabou
                    com nossas economias, e até um pacote de papel higiênico
                    seria bem vindo (rs de novo)!
                    <br />
                    <br />
                    Encerrando as brincadeiras, a lista de presentes foi
                    produzida por mera formalidade, afim de facilitar aos
                    convidados a possível vontade de nos presentear. Para nos
                    agradar, no entanto, não é necessário muito. Sua presença,
                    carinho e amizade já nos bastam, e seriam nossos maiores e
                    verdadeiros presentes!
                    <br />
                    <br />
                    Testemunhem nossa união, e considerem-nos satisfeitos.
                </div>
                <div className="stores">
                    <a
                        className="store1"
                        href="http://finalfeliz.de/larissagondo-daniloriti"
                        target="_blank"
                        rel="noreferrer"
                        data-aos="fade-right"
                        onClick={() =>
                            eventTracker('presentes', 'magazineluiza', 'logo')
                        }
                    >
                        <img src={magazineluiza} alt="Magazineluiza logo" />
                    </a>
                    <img
                        src={flowerGift}
                        alt="Flower gift"
                        data-aos="zoom-in-up"
                    />
                    <a
                        className="store2"
                        href="http://finalfeliz.de/larissagondo-daniloriti"
                        target="_blank"
                        rel="noreferrer"
                        data-aos="fade-left"
                        onClick={() =>
                            eventTracker('presentes', 'magazineluiza', 'texto')
                        }
                    >
                        <img
                            src={magaluText}
                            alt="Magazineluiza site redirect"
                        />
                    </a>
                </div>
                <div className="thankyou">Obrigado!</div>
            </section>
            {/* <section
                className="ldPhoto"
                data-parallax="scroll"
                data-image-src={ldPhoto}
                data-natural-width="100%"
            /> */}
            <section className="ldPhoto" id="ldPhoto">
                <div>
                    <img src={ldPhoto} alt="L e D" id="ldPhotoImg" />
                </div>
            </section>
            <section className="contact">
                <div className="background-title">Contato</div>
                <div className="box">
                    <div className="title">Mensagem</div>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <div className="field">
                            <Input
                                name="nome"
                                placeholder="Nome"
                                icon={BsFillPersonFill}
                            />
                        </div>

                        <div className="field">
                            <Input
                                name="email"
                                placeholder="E-mail"
                                icon={FiMail}
                            />
                        </div>

                        <div className="field">
                            <Textarea
                                name="mensagem"
                                placeholder="Mensagem..."
                            />
                        </div>

                        <Button
                            type="submit"
                            disabled={loading}
                            loading={loading}
                            large
                        >
                            ENVIAR
                        </Button>
                    </Form>
                </div>
                <div className="wrapper">
                    <div className="or">OU</div>
                </div>
                <a
                    className="email"
                    href="mailto:contato@larissaedanilo.com.br"
                >
                    <AiOutlineMail />
                    <div className="names">
                        contato<span>@larissaedanilo.com.br</span>
                    </div>
                </a>
            </section>
            <InView
                as="div"
                onChange={inViewLocal => setPassaros5Trigger(inViewLocal)}
            >
                <div ref={passaros5Element} className="passaros5" />
            </InView>
            {isLoaderRunning && <Audio />}
        </Main>
    );
}
