/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

interface PhotosProps {
    source: string;
    id: number;
}

type GalleryProps = {
    photos: Array<PhotosProps>;
    onClick: (id: number) => void;
};

const Gallery = ({ photos, onClick }: GalleryProps) => (
    <div className="manualGallery">
        <div>
            {photos.slice(0, 5).map(item => (
                <img
                    src={item.source}
                    alt="nós"
                    key={item.id}
                    onClick={e => {
                        e.preventDefault();
                        onClick(item.id);
                    }}
                />
            ))}
        </div>
        <div>
            {photos.slice(5, 9).map(item => (
                <img
                    src={item.source}
                    alt="nós"
                    key={item.id}
                    onClick={e => {
                        e.preventDefault();
                        onClick(item.id);
                    }}
                />
            ))}
        </div>
        <div>
            {photos.slice(9, 14).map((item, index) => (
                <img
                    src={item.source}
                    alt="nós"
                    key={item.id}
                    onClick={e => {
                        e.preventDefault();
                        onClick(item.id);
                    }}
                />
            ))}
        </div>
    </div>
);

export default Gallery;
